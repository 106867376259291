<template>
  <div class="sub-summary">
    <div class="sub-summary-header">
        <h2>{{data.date}}</h2>
        <div @click="$emit('close')" class="exit-btn">
            <i class="material-icons">close</i>
        </div>
    </div>
    <div class="sub-summary-content">
        <table>
            <tr>
                <th>תאריך</th>
                <th>קוד</th>
                <th>שם הסופר</th>
                <th>שם</th>
                <th>כמות (משקל)</th>
            </tr>
            <template v-for="d in data.data" :key="d.uid">
                <tr>
                    <td>{{d['date']}}</td>
                    <td>{{d['פריט']}}</td>
                    <td>{{d['counter_name']}}</td>
                    <td>{{d['שם פריט']}}</td>
                    <td>{{parseFloat(d.weight).toFixed(3)}}</td>
                </tr>
            </template>
        </table>
    </div>
  </div>
</template>

<script>
export default {
    props:['data'],
    emits:['close'],
    setup(){

        return{

        }
    }
}
</script>

<style scoped>
    .sub-summary{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.9);
        z-index: 1000;
    }
    .sub-summary-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .sub-summary-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow-y: auto;
    }
    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
</style>